import axios from "axios";

axios.defaults.headers.common["App-Version"] = "LK/1.10.2";
export const API = "https://api.classcard.ru";
// export const API = "http://test-api.orl.ueshka";
// export const API = "http://172.16.1.35:9002";

export const handleApiError = (error, action) => dispatch => {
  if (!error.response) {
    dispatch({
      type: action.type,
      payload: {
        errorStatus: 503,
        error: "Сервер недоступен"
      }
    });
  } else {
    dispatch({
      type: action.type,
      payload: {
        error: error.response.data.message,
        errorStatus: error.response.status
      }
    });
  }
};

export default API;
